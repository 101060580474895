import { Button, Container, Grid, Stepper, Step, StepLabel, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import { useQuote } from '../../../hooks/useQuote';
import DropzoneField from '../../../components/form/dropzoneField';
import settings from '../../../settings';
import { navigate } from '@reach/router';
import { DotConnector, DotStepIcon } from '../../../components/styled-stepper/styleDotStepper';
import Swal from 'sweetalert2';

const id = uuidv4();
 
const InspectionComponent = (props) => {
  const riskType = props.riskType && props.riskType.toUpperCase();
  const quote = useQuote(riskType);
  const [loading, setLoading] = useState(false);
  const { quotation, plan } = quote;

  const [files, setFiles] = useState([
    { name: 'greenIDFront', placeholder: 'Cédula verde - Frente', file: undefined },
    { name: 'greenIDBack', placeholder: 'Cédula verde - Dorso', file: undefined },
    { name: 'front', placeholder: 'Vehículo - Frente', file: undefined },
    { name: 'back', placeholder: 'Vehículo - Atrás', file: undefined },
    { name: 'rightSide', placeholder: 'Vehículo - Lateral derecho', file: undefined },
    { name: 'leftSide', placeholder: 'Vehículo - Lateral izquierdo', file: undefined },
  ]);

  const uploadSettings = {
    requestID: id,
    insuredName: quotation.quotationNumber,
    repositoryCode: '10',
    permanentlySaved: true
  };

  const handleFiles = (name, file) => {
    const formattedFiles = files.map(f => {
      if (f.name === name) {
        return {
          ...f,
          file: file
        }
      }

      return f;
    });

    setFiles(formattedFiles);
  };

  const handleDelete = (name) => {
    const formattedFiles = files.map(f => {
      if (f.name === name) {
        return {
          ...f,
          file: undefined
        };
      };

      return f;
    });

    setFiles(formattedFiles);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    setLoading(true);
    const identifierRequest = await axios.get(`${settings.quotationUrlApi}documentManagement/generateTemporaryIdentityNumber`, { headers: headers });
    console.log('Results', identifierRequest);
    const filesBody = files.map((f) => {
      const formData = new FormData();
      formData.append('files', f.file);
      formData.append('identifierType', 'polizas');
      formData.append('branch', riskType.toLowerCase().includes('auto') ? 4 : 24);
      formData.append('isTemporal', true);
      formData.append('temporaryIdentityNumber', identifierRequest.data.identifierNumber);


      return axios.post(`${settings.quotationUrlApi}documentManagement/uploadFiles`, formData, { headers: headers });
    });
    await Promise.all(filesBody);
    setLoading(false);
    Swal.fire({
      title: '',
      html: `Gracias por haber cotizado.
      <br />
      <br />
      Un asesor de atención al cliente se pondrá en contacto a la brevedad para completar el proceso de inspección y generar tu póliza.`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#f96114',
      customClass: {
        container: 'modal__message',
        loader: 'modal__message-loader'
      },
      didOpen: () => {
        navigate('/transition');
      }
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(settings.redirectConfirm);
        window.open(settings.redirectConfirm || 'https://libraseguros.com.ar/', '_self');
      };
    });
  };

  const isValid = () => {
    return files.every(f => f.file);
  };

  const steps = 7;
  return (
    <Container>
      <Stepper
        style={{ backgroundColor: "transparent" }}
        alternativeLabel
        activeStep={2}
        color="primary"
        connector={<DotConnector />}
      >
        {/* <QuoteProgress width="25%" /> */}
        {Array(steps)
          .fill("")
          .map((label) => (
            <Step key={label} color="primary">
              <StepLabel
                StepIconComponent={DotStepIcon}
              />
            </Step>
          ))}
      </Stepper>
      <form className="cardAppContainerFormBuilder" onSubmit={handleSubmit}>
        <h1>Inspección</h1>
        <legend>Cargá las imágenes indicadas en las áreas grises. Recordá que el vehículo debe poder visualizarse en su totalidad.</legend>
        <legend style={{ color: 'rgba(0, 0, 0, 0.68)', fontSize: 12, marginTop: 8 }}>Peso Máximo: <b>3MB</b></legend>
        <legend style={{ color: 'rgba(0, 0, 0, 0.68)', fontSize: 12, marginTop: 8 }}>Formatos permitidos: <b>PNG|JPG|JPEG</b></legend>
        <Grid container direction="row" style={{ marginTop: 20 }}>
          {files.map((f, i) => ((
            <Grid item style={{ marginBottom: 20 }} xs={12} md={6}>
              <DropzoneField placeholder={f.placeholder} name={f.name} getFiles={handleFiles} deleteFile={handleDelete} fileList={files} loading={loading} />
            </Grid>
          )))}
        </Grid>
        <Grid item style={{ marginBottom: 20, textAlign: 'right' }}>
          <Button
            disabled={!isValid() || loading}
            variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress color="inherit" size={24} /> : 'Siguiente'}
          </Button>
        </Grid>
      </form>
    </Container>
  )
}

export default InspectionComponent