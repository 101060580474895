import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import Handlebars from 'handlebars';
import ReactHtmlParser from 'react-html-parser';
import { useParams, navigate, useLocation } from '@reach/router';
import {
    Grid,
    FormControlLabel,
    Checkbox,
    Stepper,
    Step,
    StepLabel,
    Container,
    Button,
    makeStyles,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import LayoutLoading from '../../../components/layout/layoutLoading';
import LayoutError from '../../../components/layout/layoutError';
import EmitButton from '../../../components/quote/emitButton';
import IconAsk from '../../../components/icons/Ask';
import { AUTOS, GASTOS_MEDICOS } from '../../../utils/risksTypes';
import {
    EMITIDA,
    PENDIENTE_DE_APROBACION,
    RESUMEN,
} from '../../../utils/scores';
import { useQuote } from '../../../hooks/useQuote';
import {
    patchPlan,
    getQuote,
    resetQuotation,
} from '../../../actions/quoteActions';
import { getTemplateById } from '../../../actions/templateAction';
import {
    resetLead,
    addTrackingLead,
    getLeadByQuotationId,
} from '../../../actions/leadActions';
import {
    getInsuranceById,
    resetInsurance,
} from '../../../actions/insuranceActions';
import {
    getQuotationAndCreatePolicyRequest,
    putUpdateQuotation,
} from '../../../actions/serviceActions';
import { getClientInfo, resetClientInfo } from '../../../actions/clientInfoActions';
import { createRequestEmissionWF } from '../../../actions/requestWFActions';
import Utils from '../../../utils/utils';
import settings from '../../../settings';
import '../../../styles/resume.css';
import {
    DotConnector,
    DotStepIcon,
} from '../../../components/styled-stepper/styleDotStepper';
import Terms from '../../../components/sharing/terms';
import {
    verifyPolicy2G,
    generateLicenseNumber,
    getQuotationAndCreateProposalAPI,
    updateProposalToPolicyAPI,
    sendQuotationMail,
} from '../../../sevices/quoteService';
import { showMessage } from '../../../actions/notificationActions';
import getValue from '../../../utils/listsStatic';
import emailLayouts from '../../../components/layout/emailLayout';

const useStyles = makeStyles((theme) => ({
    modal: {
        borderRadius: 15,
    },
    modalContent: {
        padding: '47px 33px 33px !important',
    },
    button: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const QuotationSumaryComponent = ({ riskType }) => {
    const location = useLocation();
    const classes = useStyles();
    riskType = riskType && riskType.toUpperCase();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [policyNumber, setPolicyNumber] = useState(undefined);
    const [openTerm, setOpenTerm] = useState(false);
    const [isPlanSet, setIsPlanSet] = useState(false);
    const template = useSelector((state) => state.template);
    const insurances = useSelector((state) => state.insurance);
    const lead = useSelector((state) => state.lead.data);
    const quote = useQuote(riskType);
    const { plan, quotation } = quote;
    const { quotationScreenValues } = quotation;
    const clientInfo =
        quotationScreenValues && quotationScreenValues.clientInfo
            ? quotationScreenValues.clientInfo
            : {};
    const [errorPolicy2G, setErrorPolicy2G] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const hasBlackListResquest =
        (lead && lead.blackList && lead.blackList.length) || false;

    const clientInfoData = sessionStorage.getItem('quotation::clientInfo')
        ? JSON.parse(sessionStorage.getItem('quotation::clientInfo'))
        : undefined;
    const savedClientInfo = clientInfoData || clientInfo;

    const params = useParams();
    const now = moment().add(1, 'day');
    const end = now.clone().add(1, 'y');
    const handleTermsChange = (event) => setTermsAccepted(event.target.checked);

    useEffect(() => {
        if (Utils.isEmptyObject(quotation)) {
            getQuoteInfo();
        }
    }, []);

    useEffect(() => {
        if (quote && !isPlanSet && Utils.isEmptyObject(plan)) {
            recoverPlanInfo();
        }
        if (lead && !lead.data) {
            dispatch(getLeadByQuotationId(quote.quotation._id));
        }
    }, [quote]);

    const execverifyPolicy2G = async () => {
        try {
            const response = await verifyPolicy2G();
            const [policy2g] = response.result.result;
            if (policy2g.CODIGO !== 0) {
                throw new Error(
                    'Existe un error o no encuentra el codigo en la consulta de SISE'
                );
            }
            localStorage.setItem('policyNumber', policy2g.DESCRIPCION);
            setPolicyNumber(policy2g.DESCRIPCION);
        } catch (error) {
            console.log(error, 'ERROR SISE 2G');
            setErrorPolicy2G(true);
        }
    };

    useEffect(() => {
        if (quotationScreenValues && !quotationScreenValues.termsContainer) {
            //execverifyPolicy2G();
            if (lead.status !== RESUMEN) {
                dispatch(
                    addTrackingLead(
                        'Resumen de cotización',
                        riskType,
                        RESUMEN,
                        lead,
                        location.href
                    )
                );
            }
        }
    }, [lead && lead.status]);

    /* useEffect(() => {
        if (policyNumber) {
            goToDone();
        }
    }, [policyNumber]); */

    useEffect(() => {
        console.log(settings.redirectConfirm);
        window.open(settings.redirectConfirm || 'https://libraseguros.com.ar/', '_self');

        initInsurance();
        execGetClientInfo();

        // clean all
        return () => {
            dispatch(resetInsurance());
            dispatch(resetQuotation());
            dispatch(resetLead());
        };
    }, []);

    const initInsurance = () => {
        if (
            insurances &&
            insurances.data &&
            !insurances.data.length &&
            params.insuranceId
        ) {
            dispatch(getInsuranceById(params.insuranceId));
        }
    };

    useEffect(() => {
        if (Utils.isEmptyObject(plan) || (plan && !plan.name)) {
            recoverPlanInfo();
        }
    }, []);

    const recoverPlanInfo = async () => {
        const quoteInfo = sessionStorage.getItem(`quotation::quote::${riskType}`);
        if (quoteInfo && !Utils.isEmptyObject(quoteInfo.plan)) {
            dispatch(patchPlan(quoteInfo.plan));
            setIsPlanSet(true);
        }
    };

    const execGetClientInfo = () => {
        dispatch(getClientInfo(params.resultQuoteId));
    };

    useEffect(() => {
        execGetClientInfo();
    }, []);

    /* useEffect(() => {
        console.log('Paso 8');
        if (riskType && insurances.data.length) {
            const insurance = insurances.data.find(
                (insurance) => insurance.riskType === riskType
            );
            dispatch(getTemplateById(insurance.options.layoutDirectEmission));
        }
    }, [riskType, insurances]); */

    const getQuoteInfo = async () => {
        const quotationId = params.resultQuoteId;
        dispatch(getQuote(quotationId));
    };

    const getDataToPdfCotizacion = (quotationData) => {
        let productsInformation = {};
        let coverages = {};
        const coveragesOrdered = [];

        productsInformation = quotationData.products.map((product) => {
            return { name: product.name, price: product.price };
        });

        const productOrder = productsInformation.map((product) => product.name);

        coverages = quotationData.products.map((product) => {
            product.coverages.map(
                (coverage) => (coverage.productName = product.name)
            );
            return product.coverages;
        });

        coverages.map((coverage) => coveragesOrdered.push(...coverage));

        coveragesOrdered.sort((a, b) => {
            if (a.name > b.name) {
                return -1;
            }

            if (a.name < b.name) {
                return 1;
            }

            return 0;
        });

        let currentCoverageName = coveragesOrdered[0].name;
        let coverageByProduct = productsInformation.map(() => { });
        const result = [];

        while (coveragesOrdered.length > 0) {
            if (currentCoverageName !== coveragesOrdered[0].name) {
                if (coverageByProduct[0] === undefined) {
                    coverageByProduct[0] = { name: currentCoverageName };
                }

                currentCoverageName = coveragesOrdered[0].name;

                result.push(coverageByProduct);
                coverageByProduct = productsInformation.map(() => { });
            } else {
                const coverage = coveragesOrdered.shift();
                const index = productOrder.findIndex(
                    (product) => product === coverage.productName
                );
                coverageByProduct[index] = coverage;
            }
        }

        return {
            coverages: result,
            products: productsInformation,
        };
    };

    const getTemplateByWorkflow = async (workflowName) => {
        const templates = [];
        let quotationData = '';
        const finalPolicyNumber =
            policyNumber || localStorage.getItem('policyNumber');

        const userName = quotationScreenValues.name
            ? quotationScreenValues.name
            : quotationScreenValues.nombreAsegurado;

        const firstPlan =
            quotation.products && quotation.products.length
                ? quotation.products[0]
                : {};

        const lifetimeMax = firstPlan.coverages.find(
            (coverages) => coverages.name === 'Máximo Vitalicio'
        );

        quotationData = {
            ...quotation,
            quotationScreenValues: {
                ...quotation.quotationScreenValues,
                policyNumber: finalPolicyNumber,
            },
        };
        if (quotation && quotation.creationDate) {
            quotationData.creationDate = new Date(
                quotation.creationDate
            ).toLocaleDateString();
        }

        const { coverages, products } = getDataToPdfCotizacion(quotationData);

        if (workflowName === 'Emision directa') {
            const coverAbank = {
                name: 'Caratula - Abank',
                templateData: {
                    quotation: quotationData,
                    plan,
                    person: {
                        name: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                    },
                    validity: {
                        start: {
                            day: `${now.format('DD')}`,
                            month: now.get('month') + 1,
                            monthLabel: `${now.format('MMMM')}`,
                            year: now.get('y'),
                            time: '12:00 PM',
                        },
                        end: {
                            day: `${end.format('DD')}`,
                            month: end.get('month') + 1,
                            monthLabel: `${end.format('MMMM')}`,
                            year: end.get('y'),
                            time: '12:00 PM',
                        },
                    },
                },
            };

            const healthDeclaration = {
                name: 'Declaración de Salud - Abank',
                templateData: {
                    data: quotationScreenValues.healthDeclaration || {},
                },
            };

            const integralFileData = getIntegralFileData();
            const integralFile = {
                name: 'Ficha Integral - Abank',
                templateData: {
                    ...(quotationScreenValues.economicActivity || {}),
                    ...(quotationScreenValues.affidavit || {}),
                    ...(quotationScreenValues.pep || {}),
                    date: moment().format('L'),
                    phone: clientInfo.phone,
                    municipality: getValue.municipality[clientInfo.municipios],
                    department: getValue.department[clientInfo.department],
                    economicActivityAddressDepartment: quotationScreenValues.clientVinculation.department,
                    economicActivityMunicipality: quotationScreenValues.clientVinculation.municipality,
                    maritalStatus: quotationScreenValues.document.maritalStatus,
                    name: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                    deleteTableLines: 'FALSE',
                    ...integralFileData,
                },
            };

            templates.push(coverAbank);
            templates.push(healthDeclaration);
            templates.push(integralFile);

            // imc viene del storage
            const imcType = quotationScreenValues.imc.imcType || undefined;

            if (
                imcType === 'Obesidad Grado I' ||
                imcType === 'Obesidad Grado II'
            ) {
                const exclusionLetter = {
                    name: 'Carta de exclusión - Abank',
                    templateData: {
                        date: moment().format('L'),
                        titular: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                        policyNumber: finalPolicyNumber,
                    },
                };

                templates.push(exclusionLetter);
            }
        } else if (workflowName === 'Solicitud de emision') {
            const healthDeclaration = {
                name: 'Declaración de Salud - Abank',
                templateData: {
                    data: quotationScreenValues.healthDeclaration || {},
                },
            };

            const integralFileData = getIntegralFileData();
            const integralFile = {
                name: 'Ficha Integral - Abank',
                templateData: {
                    ...(quotationScreenValues.economicActivity || {}),
                    ...(quotationScreenValues.affidavit || {}),
                    ...(quotationScreenValues.pep || {}),
                    date: moment().format('L'),
                    phone: clientInfo.phone,
                    municipality: getValue.municipality[clientInfo.municipios],
                    department: getValue.department[clientInfo.department],
                    economicActivityAddressDepartment: quotationScreenValues.clientVinculation.department,
                    economicActivityMunicipality: quotationScreenValues.clientVinculation.municipality,
                    maritalStatus: quotationScreenValues.document.maritalStatus,
                    name: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                    deleteTableLines: 'FALSE',
                    ...integralFileData,
                },
            };

            templates.push(healthDeclaration);
            templates.push(integralFile);
        }

        const abankQuote = {
            name: 'Cotizacion GM - Abank',
            templateData: {
                quotation: {
                    ...quotationData,
                    quotationScreenValues: {
                        ...quotationScreenValues,
                        policyNumber: finalPolicyNumber,
                    },
                },
                products,
                coverages,
                dateCurrent: moment().format('l'),
                plan: {
                    name: firstPlan.name,
                    price: (firstPlan.price / 12).toFixed(2),
                    frequency: 'mensual',
                    premium: firstPlan.premium,
                    lifetimeMax: lifetimeMax ? lifetimeMax.value : undefined,
                },
                person: {
                    name: userName,
                },
                validity: {
                    start: {
                        day: `${now.format('DD')}`,
                        month: now.get('month') + 1,
                        monthLabel: `${now.format('MMMM')}`,
                        year: now.get('y'),
                        time: `${now.format('hh:mm A')}`,
                    },
                    end: {
                        day: `${end.format('DD')}`,
                        month: end.get('month') + 1,
                        monthLabel: `${end.format('MMMM')}`,
                        year: end.get('y'),
                        time: `${now.format('hh:mm A')}`,
                    },
                },
            },
        };

        const paymentDetails = {
            name: 'Detalle de pago - Abank',
            templateData: {
                paymendID: quotationScreenValues.paymentId,
                name: plan.name,
                price: plan.price,
                monthlyPrice: (plan.price / 12).toFixed(2),
                policyNumber: finalPolicyNumber,
            },
        };

        const duiPdf = {
            name: 'Documento de identificacion - Abank',
            templateData: {
                duiFront: localStorage.getItem('recognition::frontDUIBase64'),
                duiBack: localStorage.getItem('recognition::backDUIBase64'),
            },
        };

        const data = await getInsuranceApplicationData();

        const insuranceApplication = {
            name: 'Solicitud de Seguros - Abank',
            templateData: {
                ...data,
            },
        };

        templates.push(abankQuote);
        templates.push(paymentDetails);
        templates.push(duiPdf);
        templates.push(insuranceApplication);
        return templates;
    };

    const createRequestWF = async (data) => {
        const { workflowName } = data;

        // Begin create workflow request without token
        if (!Utils.isEmptyObject(quotation) && !Utils.isEmptyObject(plan)) {
            const { quotationScreenValues } = quotation;
            const quotationData = {};

            const fullname =
                `${quotationScreenValues.name || ''} ${quotationScreenValues.surname || ''
                    }`.trim() ||
                (quotationScreenValues &&
                    quotationScreenValues.nombreAsegurado);
            const isOrienteDomain =
                window.location.hostname.includes('oriente');
            const plan = getPlanToSendPDF();

            const templates = await getTemplateByWorkflow(workflowName);

            const requestWFData = {
                title: fullname,
                description: `cotizacion ${isOrienteDomain ? 'Oriente' : 'Abank'
                    }`,
            };

            const info = {
                templates,
                requestWFData,
                workflowName,
            };

            if (!hasBlackListResquest && workflowName === 'Emision directa') {
                dispatch(createRequestEmissionWF(info));
            } else if (workflowName === 'Solicitud de emision') {
                dispatch(createRequestEmissionWF(info));
            }
        }
        // end direct emission workflow
    };

    const handleUploadImages = async (policy) => {
        const identifierNumber = sessionStorage.getItem('identifier__number');
        const headers = {
            "Content-Type": "application/json",
        };

        const obj = {
            identifierNumber: identifierNumber,
            identifierType: 'polizas',
            branch: riskType.toLowerCase().includes('auto') ? 4 : 24,
            newIdentifierNumber: policy
        };
        await axios.post(`${settings.quotationUrlApi}documentManagement/changeTemporyToComplete`, obj, { headers: headers });
    };

    const sendMail = async (data) => {
        const emailTemplate = Handlebars.compile(emailLayouts[riskType]);
        const dataEmail = {
            url: `${settings.authUrlBase}login/insured`,
            name: data.name,
            lastname: data.lastname
        };

        const emailDataToSend = {
            subject: '¡Bienvenido al Mundo LIBRA!',
            message: emailTemplate(dataEmail),
            email: data.email
        };

        await sendQuotationMail({ emailDataToSend });
    };

    const goToDone = async () => {
        /* if (errorPolicy2G) {
            dispatch(
                showMessage({
                    redirect: null,
                    variant: 'alert',
                    type: 'error',
                    message:
                        'No podemos procesar su solicitud en este momento. Por favor, comuniquese para mayor detalle',
                })
            );
            return;
        } */
        const selectedPlan = JSON.parse(sessionStorage.getItem(`quotation::quote::${riskType}`));

        moment.locale('es');

        /* const quoteBody = {
            quotationId: quotation._id,
            proposalData: {
                id_pv_wkf: selectedPlan?.plan?._id,
                Ramo: riskType.toLowerCase() === 'automotores' ? 4 : 24,
                propuesta_riesgo: [
                    {
                        codItem: 1,
                        escerokm: quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.kilometraje,
                        codigocombustible: 1,
                        codigocarroceria: 1,
                        numerochasis: savedClientInfo.vehicleChassisNumber,
                        numeromotor: savedClientInfo.vehicleEngineNumber,
                        patente: savedClientInfo.patent,
                        color: 1
                    }
                ],
                insured: {
                    is_payer: 1,
                    apellido1: "",
                    apellido2: null,
                    nombre: `${savedClientInfo.name} ${savedClientInfo.lastname}`,
                    codtipodoc: 1,
                    nrodoc: savedClientInfo.documentNumber,
                    nronit: null,
                    fecnac: savedClientInfo.birthday,
                    txtsexo: savedClientInfo.gender,
                    email: savedClientInfo.email,
                    telephonePrefix: "",
                    telefono: savedClientInfo.phone,
                    nro_cod_postal: quotationScreenValues?.quoteData?.RiesgosPorRamo[0]?.cp,
                    cod_pais: 1,
                    nro_apto: null,
                    txt_direccion: savedClientInfo.street,
                    sp_nro_cta_tarj: savedClientInfo.creditCardNumber,
                    sp_aaaamm_vto_tarj: savedClientInfo.creditCardExpiryDate.split(' / ').reverse().map((f, i) => { if (i === 0) return `20${f}`; return f; }).join(''),
                    tipo_persona: "F",
                    sp_cod_conducto: 7,
                    contactType: 1,
                    cod_tipo_dir: 1,
                    cod_condicion_imp: 1,
                    cod_dpto: quotationScreenValues?.quoteData?.Provincia,
                    cod_municipio: quotationScreenValues?.quoteData?.Municipio,
                    cod_colonia: 1,
                    sp_ind_conducto: 0,
                    sp_imp_limite_tarj: 0,
                    sp_cod_banco_emisor_tarj: 0,
                    sp_sn_habilitado: 0,
                    nro_nro2: 0
                }
            }
        } */
        const proposalBody = {
            _id: quotation._id,
            status: 'Emitida',
            calculationType: 'libra',
            productSelected: selectedPlan?.plan?._id,
            firstpaid: true
        };
        setIsLoading(true);
        Swal.fire({
            title: '',
            html: 'Estamos trabajando en la generación de tu póliza, por favor aguardá unos instantes mientras finaliza el proceso.',
            didOpen: () => {
                Swal.showLoading();
            },
            customClass: {
                container: 'modal__message',
                loader: 'modal__message-loader'
            }
        });
        //await dispatch(getQuotationAndCreatePolicyRequest(quotation._id));
        //await getQuotationAndCreateProposalAPI(quoteBody);
        const proposal = await updateProposalToPolicyAPI(proposalBody);
        sessionStorage.setItem('policy', proposal?.externalPolicy?.Nro_pol);
        handleUploadImages(proposal?.externalPolicy?.Nro_pol);
        sendMail(savedClientInfo);
        setIsLoading(false);

        let imcType;
        if (quotationScreenValues.imc) {
            imcType = quotationScreenValues.imc.imcType || undefined;
        }
        const shouldEmit = quotationScreenValues.emitOrRequest || undefined;
        const quotationWithoutProducts = { ...quotation };
        delete quotationWithoutProducts.products;
        dispatch(
            putUpdateQuotation({
                ...quotationWithoutProducts,
                quotationScreenValues: {
                    ...quotationScreenValues,
                    policyNumber: proposal?.externalPolicy?.Nro_pol,
                    termsContainer: true,
                },
            })
        );
        Swal.hideLoading();
        Swal.close();
        if (hasBlackListResquest || !shouldEmit) {
            const data = { workflowName: 'Solicitud de emision', data: {} };
            updateTrackingCallback();
            // console.log('Creating WF');
            // await createRequestWF(data);
            dispatch(resetClientInfo());
            navigate("/quote/completedNeedApproval");
        } else {
            if (riskType.toLowerCase().includes('medicos')) {
                await sendPDFData();
            }
            updateTrackingCallback();
            const data = {
                workflowName: 'Emision directa',
                data: { imcType },
            };
            // console.log('Creating WF');
            // await createRequestWF(data);
            dispatch(resetClientInfo());
            navigate("/quote/completed");
        }
    };

    const getParticularConditions = () => {
        const productSelected = plan.name
            .toLocaleUpperCase()
            .replace(' ', '_')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        return `CONDICIONES_PARTICULARES_rv1_${productSelected}`;
    };

    const getInsuranceApplicationData = async () => {
        const data = await getInfoCarnet();
        const idCardMain = data[0].IdCard;

        const deleteTableLines = "FALSE";
        const names = quotationScreenValues.document.name;
        const lastNames = quotationScreenValues.document.surname;
        const documentNumber = quotationScreenValues.document.personalNumber;
        const documentType = clientInfo.select1;
        const nit = quotationScreenValues.document.nit;
        const age = quotationScreenValues.age;
        const gender = quotationScreenValues.document.gender;
        const dateBirthday = quotationScreenValues.document.dateOfBirth;
        const nationality = quotationScreenValues.document.nationality;
        const maritalStatus = quotationScreenValues.document.maritalStatus;
        const profession = quotationScreenValues.document.profession;
        const weight = quotationScreenValues.clientVinculation.peso;
        const height = quotationScreenValues.clientVinculation.estatura;
        const email = quotation.lead;
        const address = clientInfo.address;
        const phoneNumber = clientInfo.phone;
        const dependents = setDate(quotationScreenValues.items);
        const questionA = clientInfo.radio0;
        const questionB = clientInfo.radio1;
        const certificateNumber = idCardMain;

        const [firtName, secondName] = names.split(' ');
        const [firtSurname, secondSurname] = lastNames.split(' ');

        const result = {
            day: moment().format('DD'),
            month: moment().format('MM'),
            year: moment().format('YYYY'),
            plan: plan.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            firtName,
            secondName,
            firtSurname,
            secondSurname,
            deleteTableLines,
            names,
            lastNames,
            documentNumber,
            documentType,
            nit,
            age,
            gender,
            dateBirthday,
            nationality,
            maritalStatus,
            profession,
            weight,
            height,
            email,
            address,
            phoneNumber,
            dependents,
            policyNumber: policyNumber || localStorage.getItem('policyNumber'),
            questionA,
            questionB,
            certificateNumber,
        };

        return result;
    };

    const setDate = (items) => {
        return items.map((item) => {
            return Object.assign(
                {},
                {
                    ...item,
                    age_birthdate: moment(item.age_birthdate).format('L'),
                }
            );
        });
    };

    const getInfoCarnet = async () => {
        const finalPolicyNumber =
            policyNumber || localStorage.getItem('policyNumber');
        const infoCarnet = [];
        const items = [...quotationScreenValues.items];
        const contractingName = `${quotationScreenValues.name} ${quotationScreenValues.surname}`;
        let hasWife = false;
        let numberChildren = 0;

        const contractor = {
            name: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
            contractingName,
            birthdayDate: moment(quotationScreenValues.age_birthdate).format(
                'L'
            ),
            policyNumber: finalPolicyNumber,
            plan: {
                name: plan.name,
            },
            typeCard: 'carnet_titular',
        };

        infoCarnet.push(contractor);

        items.map((item) => {
            let name = '';
            let typeCard = '';

            if (item.groupName === 'conyuge') {
                name = item.nameconyuge;
                hasWife = true;
                typeCard = 'carnet_conyugue';
            } else if (item.groupName === 'hijos') {
                name = item.namehijo;
                numberChildren += 1;
                typeCard = `carnet_hijo${numberChildren}`;
            }

            infoCarnet.push({
                name,
                contractingName,
                birthdayDate: moment(item.age_birthdate).format('L'),
                policyNumber: finalPolicyNumber,
                plan: {
                    name: plan.name,
                },
                typeCard,
            });
        });

        let licenseNumberByInsuredType = '';
        try {
            licenseNumberByInsuredType = await generateLicenseNumber(
                finalPolicyNumber,
                hasWife,
                numberChildren
            );
            licenseNumberByInsuredType = {
                ...licenseNumberByInsuredType.result.result[0],
            };
        } catch (error) {
            console.log('error', error);
        }

        const keysLicenseNumber = Object.keys(licenseNumberByInsuredType);

        infoCarnet.map((carnet) => {
            if (keysLicenseNumber.includes(carnet.typeCard)) {
                carnet.IdCard = licenseNumberByInsuredType[carnet.typeCard];
            }
        });

        return infoCarnet;
    };

    const getIntegralFileData = () => {
        const documentNumber = quotationScreenValues.document.personalNumber;
        const nit = quotationScreenValues.document.nit;
        const placeAndDateOfBirth = `${quotationScreenValues.document.stateOfBirth} ${quotationScreenValues.document.cityOfBirth} ${quotationScreenValues.document.dateOfBirth}`;
        const placeAndDateOfIssueDui = `${quotationScreenValues.document.stateOfIssuance} ${quotationScreenValues.document.cityOfIssuance} ${quotationScreenValues.document.dateOfIssuing}`;
        const residenceAdress = clientInfo.address; // dirección residencia
        const email = quotation.lead;
        const wifeInformation = quotationScreenValues.items.filter(
            (item) => item.groupName === 'conyuge'
        );
        const wifeName =
            wifeInformation.length > 0 ? wifeInformation[0].nameconyuge : '';

        return {
            documentNumber,
            nit,
            placeAndDateOfBirth,
            placeAndDateOfIssueDui,
            residenceAdress,
            email,
            wifeName,
        };
    };

    const sendPDFData = async () => {
        const headers = { 'Content-Type': 'application/json' };

        const selectedPlan = getPlanToSendPDF();

        const dataToPdf = {
            name: 'Caratula - Abank',
            templateData: {
                quotation: {
                    ...quotation,
                    quotationScreenValues: {
                        ...quotation.quotationScreenValues,
                        policyNumber:
                            policyNumber ||
                            localStorage.getItem('policyNumber'),
                    },
                },
                plan: selectedPlan,
                person: {
                    ...savedClientInfo,
                    additionalInfo: clientInfo,
                },
                validity: {
                    start: {
                        day: `${now.format('DD')}`,
                        month: now.get('month') + 1,
                        monthLabel: `${now.format('MMMM')}`,
                        year: now.get('y'),
                        time: '12:00 PM',
                    },
                    end: {
                        day: `${end.format('DD')}`,
                        month: end.get('month') + 1,
                        monthLabel: `${end.format('MMMM')}`,
                        year: end.get('y'),
                        time: '12:00 PM',
                    },
                },
            },
        };

        moment.locale('es');
        const dateNow = moment();

        const generalConditions = {
            name: 'CONDICION_GENERAL-SEGURO_INDIVIDUAL_TRADICIONAL',
            templateData: {},
            isPDF: true,
        };

        const integralFileData = getIntegralFileData();
        const integralFile = {
            name: 'Ficha Integral - Abank',
            templateData: {
                ...(quotationScreenValues.economicActivity || {}),
                ...(quotationScreenValues.affidavit || {}),
                ...(quotationScreenValues.pep || {}),
                date: moment().format('L'),
                phone: clientInfo.phone,
                municipality: getValue.municipality[clientInfo.municipios],
                department: getValue.department[clientInfo.department],
                economicActivityAddressDepartment: quotationScreenValues.clientVinculation.department,
                economicActivityMunicipality: quotationScreenValues.clientVinculation.municipality,
                maritalStatus: quotationScreenValues.document.maritalStatus,
                name: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                deleteTableLines: 'FALSE',
                ...integralFileData,
            },
        };

        const particularConditions = {
            name: getParticularConditions(),
            templateData: {},
            isPDF: true,
        };

        const instructionsForRefunds = {
            name: 'INSTRUCTIVO_PARA_REEMBOLSOS',
            templateData: {},
            isPDF: true,
        };

        const carnet = {
            name: 'Carnet - Abank',
            templateData: {
                insureds: await getInfoCarnet(),
            },
        };

        const medicalNetworkInstructionsForUse = {
            name: 'INSTRUCTIVO_DE_USO_DE_RED_MEDICA',
            templateData: {},
            isPDF: true,
        };

        const templates = [];
        templates.push(dataToPdf);
        templates.push(generalConditions);
        templates.push(integralFile);
        templates.push(particularConditions);
        templates.push(instructionsForRefunds);
        templates.push(medicalNetworkInstructionsForUse);
        templates.push(carnet);

        const imcType = quotationScreenValues.imc.imcType || undefined;

        if (imcType === 'Obesidad Grado I' || imcType === 'Obesidad Grado II') {
            const exclusionLetter = {
                name: 'Carta de exclusión - Abank',
                templateData: {
                    date: moment().format('L'),
                    titular: `${quotationScreenValues.name} ${quotationScreenValues.surname}`,
                    policyNumber:
                        policyNumber || localStorage.getItem('policyNumber'),
                },
            };

            templates.push(exclusionLetter);
        }

        if (riskType.toLowerCase().includes('medicos')) {
            try {
                const userName = `${quotationScreenValues.name} ${quotationScreenValues.surname}`;
                const emailTemplate = Handlebars.compile(
                    template.data.newTemplate
                );
                const dataEmail = { name: userName };

                await axios({
                    method: 'post',
                    headers,
                    url: `${settings.quotationUrlApi}contact/quotation/send`,
                    data: {
                        templates,
                        emailDataToSend: {
                            subject: '¡Bienvenido a Aseguradora ABANK!',
                            message: emailTemplate(dataEmail),
                            email: quotation.lead,
                            filename: 'template.pdf',
                        },
                    },
                });
            } catch (error) {
                console.log('ocurrio un error enviando PDF. ', error);
            }
        }
    };

    const setDefaultFormat = () => {
        if (quotationScreenValues.hasOwnProperty('hash')) {
            // hash property is not needed by client, remove for this view
            delete quotationScreenValues.hash;
        }

        const formatText = Object.keys(quotationScreenValues)
            .map(
                (key) =>
                    `<strong>${key}:</strong> ${quotationScreenValues[key]}`
            )
            .join(', ');
        return `<p className="quote__description">${formatText}</p>`;
    };

    const setTextInFormat = () => {
        if (!insurances.data.length || !quotationScreenValues) {
            return '';
        }

        const insurance = insurances.data.find(
            ($in) => $in._id === quotation.insuranceId
        );
        var textFormated =
            insurance.format && insurance.format.header
                ? insurance.format.header.toString()
                : false;

        if (!textFormated) {
            return setDefaultFormat();
        }

        for (const property in quotationScreenValues) {
            const toSearch = '$' + `{{${property}}}`;
            const replaceFor = quotationScreenValues[property];
            textFormated = textFormated.replace(toSearch, replaceFor);
        }

        return `<p className="quote__description">${textFormated}</p>`;
    };

    const getSumary = () => (
        <>
            <p className="quote__description">
                <span>Datos: </span>
            </p>

            {ReactHtmlParser(setTextInFormat())}

            {quotationScreenValues.totalAmmount && (
                <div className="quote__container--price">
                    <p className="quote__description--price">
                        <span>Valor asegurado: </span>
                        {`$ ${quotationScreenValues.totalAmmount.toLocaleString(
                            'en-AR'
                        )}`}
                    </p>
                    <IconAsk type="primary" />
                </div>
            )}
        </>
    );

    const getPlanToSendPDF = () => {
        const productIdSelected = plan.productId;
        const product = quotation.products.find(
            (product) => product.productId === productIdSelected
        );
        const coverage = product.coverages.find(
            (coverage) => coverage.name === 'Máximo Vitalicio'
        );
        const lifetimeMax = coverage && coverage.value;

        plan.lifetimeMax = lifetimeMax;
        plan.frequency = 'mensual';

        return Object.assign({}, plan);
    };

    useEffect(() => {
        if (!plan._id && quotation.productSelected) {
            const plan = quotation.products.find(
                (product) => product.productId === quotation.productSelected
            );
            if (plan) {
                dispatch(patchPlan(plan));
            }
        }
    }, [quotation._id]);

    useEffect(() => {
        if (isPlanSet && quotation.productSelected && !Utils.isEmptyObject(clientInfo)) {
            // goToDone();
        }
    }, [quotation, clientInfo, isPlanSet]);

    const updateTrackingCallback = (trackingData = 'Finalizado') => {
        if (!quotationScreenValues.emitOrRequest) {
            trackingData = 'Solicitud de emision directa creada';
        }

        dispatch(
            addTrackingLead(
                trackingData,
                riskType,
                EMITIDA,
                lead
            )
        );
    };

    const getDescription = (key, value) => {
        const quotation = JSON.parse(sessionStorage.getItem(`quotation::${riskType.toUpperCase()}`));
        if (quotation) {
            const field = quotation.groups[0].fields.find(f => f.name === key);

            if (field) {
                const option = field.options.find(opt => opt._id == value);
                if (option)
                    return option.name;
            }
        }

        return value;
    };

    const contentByType = () => {
        switch (riskType) {
            case GASTOS_MEDICOS:
                return (
                    <div className="insured-container">
                        <h4>Asegurados</h4>
                        <div className="plan-coverages-container">
                            <div>
                                <div>{`${clientInfo.name} ${clientInfo.surname}`}</div>
                                <div>
                                    <span>
                                        {quotation.quotationScreenValues.age}{' '}
                                        años
                                    </span>
                                </div>
                            </div>
                            {quotation &&
                                quotation.quotationScreenValues.items &&
                                quotation.quotationScreenValues.items.map(
                                    (item, index) => {
                                        return (
                                            <div key={index}>
                                                <div>
                                                    <strong>
                                                        {item.groupName.replace(
                                                            /[s]$/,
                                                            ''
                                                        )}
                                                        :
                                                    </strong>{' '}
                                                    {item.groupName ===
                                                        'conyuge'
                                                        ? item.nameconyuge
                                                        : item.namehijo}
                                                </div>
                                                <div>
                                                    <span>
                                                        {item.groupName ===
                                                            'conyuge'
                                                            ? item.spouseAge
                                                            : item.age}{' '}
                                                        años
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                );
            case AUTOS:
                return (
                    <div className="insured-container">
                        <h4>Auto</h4>
                        <div className="plan-coverages-container">
                            <div>
                                <div>Año</div>
                                <div>
                                    <span>
                                        {quotation.quotationScreenValues.year || quotation?.quotationScreenValues?.quoteData.RiesgosPorRamo[0]?.AnioVehiculo}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div>Marca</div>
                                <div>
                                    <span>
                                        {quotation.quotationScreenValues.brand || getDescription('Marca', quotation?.quotationScreenValues?.quoteData.RiesgosPorRamo[0]?.Marca)}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div>Modelo</div>
                                <div>
                                    <span>
                                        {quotation.quotationScreenValues.model || getDescription('Modelo', quotation?.quotationScreenValues?.quoteData.RiesgosPorRamo[0]?.Modelo)}
                                    </span>
                                </div>
                            </div>
                            {!quotation?.quotationScreenValues?.quoteData && <div>
                                <div>Version</div>
                                <div>
                                    <span>
                                        {
                                            quotation.quotationScreenValues
                                                .version
                                        }
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </div>
                );
        }
    };
    const steps = 7;
    const titleBtnEmitOrRequest =
        !hasBlackListResquest &&
            quotationScreenValues &&
            quotationScreenValues.emitOrRequest
            ? 'Emitir'
            : 'Solicitar';

    const handleModalTermClose = () => {
        setOpenTerm(false);
    };

    const handleGoToTerms = () => {
        console.log(settings.redirectConfirm);
        window.open(settings.redirectConfirm || 'https://libraseguros.com.ar/terminos-y-condiciones/', '_blank');
    }

    return (
        <>
            <LayoutLoading />
            {/* <Dialog
                classes={{
                    paper: classes.modal,
                }}
                open={openTerm}
                onClose={handleModalTermClose}
            >
                <DialogContent
                    classes={{
                        root: classes.modalContent,
                    }}
                >
                    <Terms />
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleModalTermClose}
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Container>
                {(isLoading || quote.loading) && <LayoutLoading />}
                {quote.error && <LayoutError />}
                {!quote.loading && quote.quotation && quote.quotation.products && (
                    <>
                        <Stepper
                            style={{ backgroundColor: 'transparent' }}
                            alternativeLabel
                            activeStep={5}
                            connector={<DotConnector />}
                        >
                            {Array(steps)
                                .fill('')
                                .map((label, index) => (
                                    <Step key={label + index}>
                                        <StepLabel
                                            StepIconComponent={DotStepIcon}
                                        />
                                    </Step>
                                ))}
                        </Stepper>

                        <Grid item xs className="cardAppContainer">
                            <div className="plan-container">
                                <span>Plan: {plan?.name}</span>
                            </div>
                            <div className="plan-coverages-container">
                                <div>
                                    <div>Importe</div>
                                    <div>
                                        <span>
                                            $
                                            {parseFloat(
                                                plan?.price / 12
                                            ).toFixed(2)}{' '}
                                            / mes
                                        </span>
                                        <span>${plan?.price} / anual</span>
                                    </div>
                                </div>
                                {plan &&
                                    plan?.coverages &&
                                    plan?.coverages.map((coverage) => (
                                        <div key={coverage?._id}>
                                            <div>{coverage?.name}</div>
                                            <div>
                                                <span>{coverage?.value}</span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {contentByType()}
                            {quotationScreenValues &&
                                quotationScreenValues.termsContainer && (
                                    <div className="validity-message-container">
                                        Cotización Emitida
                                    </div>
                                )}
                            {quotationScreenValues &&
                                !quotationScreenValues.termsContainer && (
                                    <>
                                        <div className="validity-message-container">
                                            El inicio de vigencia de la poliza
                                            contratada es el{' '}
                                            {now.format('DD/MM/YYYY')} a partir
                                            de las 12 PM
                                        </div>
                                        {/* <Button
                                            className={classes.button}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleGoToTerms()}
                                        >
                                            Términos y condiciones
                                        </Button>

                                        <div className="terms-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={
                                                            handleTermsChange
                                                        }
                                                        checked={termsAccepted}
                                                    />
                                                }
                                                label="Acepto los términos y condiciones"
                                            />
                                        </div>

                                        <EmitButton
                                            label={titleBtnEmitOrRequest}
                                            onClick={goToDone}
                                        />
                                    </>
                                )}
                            <div className="date-container">
                                <small>
                                    Fecha:{' '}
                                    {moment().format('DD/MM/YYYY hh:mm A')}
                                </small>
                            </div>
                        </Grid>
                    </>
                )}
            </Container> */}
        </>
    );
};

export default QuotationSumaryComponent;
